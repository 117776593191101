import ctx from '../../../plugins/axios';

export default async (payload) => {
  try {
    const { data, status } = await ctx.get(`/er-overview?active_flag=${payload?.active_flag}&metric_label=${payload?.metric_label}&supplier_name=${payload?.supplier_name}&platform=${payload?.platform}&count=${payload?.count}&pageSize=${payload?.pageSize}&pageNumber=${payload?.pageNumber}`);    
    return { data, status };
  } catch (error) {
    return { error };
  }
};
