import {LdsModal, LdsButton, LdsIcon, LdsLoadingSpinner, LdsPagination, LdsTable, LdsTextField, LdsTile, useToastContext, LdsLink } from '@elilillyco/ux-lds-react'
import React, { useEffect, useState } from 'react'
import HeaderNav from '../HeaderNav'
import { useDispatch, useSelector } from 'react-redux';
import { editException, fetchException,fetchExceptionEntries } from '../../store/Components/OMMS/exceptionReporting';
import "../../assets/components/OMMS/exceptionReporting.scss";

const columns = [
    'SupplierName',
    'Platform',
    'MetricLabel',
    'ActivityCount',
];

const ExceptionReporting = () => {
    const [groupedData, setGroupedData] = useState([]);
    const [apiResEntries, setApiResEntries] = useState([]);
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [remainingIDs, setRemainingIDs] = useState([]);
    const [see, setSee] = useState(false);
    const dispatch = useDispatch();
    const [apiRes, setApiRes] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [inputColumn, setInputColumn] = useState(null);
    const [searchValue, setSearchValue] = useState({
        supplierName: '',
        Platform: '',
        MetricLabel: '',
        activityCount: '',
    })
    const [inputColumns, setInputColumns] = useState({});
    const [totalPage, setTotalPage] = useState()
    const [statusSearchFld1, setIsON1] = React.useState(false);
    const [statusSearchFld2, setIsON2] = React.useState(false);
    const [metricLabel, setMetricLabel] = useState('')
    const [supplierName, setSupplierName] = useState('')
    const [statusSearchFld3, setIsON3] = React.useState(false);
    const [statusSearchFld4, setIsON4] = React.useState(false);
    const [platform, setPlatform] = useState('')
    const [activityCount, setActivityCount] = useState('')

    const deploy = {
        "edit_values": {
            "exception_id": selectedRows,
            "is_deployed": true
        }
    }
    const deployHere = {
        "edit_values": {
            "exception_id": selectedRows,
            "is_deployed_here": true
        }
    }
    const reviewNotNeed = {
        "edit_values": {
            "exception_id": selectedRows,
            "active_flag": false
        }
    }

    const apiPayload = {
        active_flag: true,
        supplier_name: supplierName,
        metric_label: metricLabel,
        platform: platform,
        count: activityCount,
        pageSize: 10,
        pageNumber: currentPage,
        status: '',
        contact_type: '',
    };
    const loader = useSelector(({ exceptionSlice }) => exceptionSlice.loader);
    const totalPages = useSelector(({ exceptionSlice }) => exceptionSlice.totalPages)
    const { addToast } = useToastContext();

    useEffect(() => {
        dispatch(fetchException(apiPayload)).then((d) => (setApiRes(d?.payload?.data), setTotalPage(d?.payload?.numberOfPages)));
    }, [currentPage]);

    const handleEdit = (type) => {
        const action = type === 'rnn' ? reviewNotNeed : deploy;
        setApiRes()
        dispatch(editException(action))
            .then(() => {
                dispatch(fetchException(apiPayload))
                    .then((d) => {
                        setApiRes(d?.payload?.data);
                    });
                addToast({
                    toastMessage: "Your changes have been saved successfully ",
                    actionText: "",
                    variant: "success",
                    position: "top",
                    align: "center",
                    dismissible: true,
                    light: false,
                    timeout: 8000,
                    inline: false,
                    autoDismiss: true,
                    zIndex: 11
                })
                setSee(false);
                return;
            });
    }
    const deployButton = (type) => {
        if (selectedRows.length < 1) {
           return  addToast({
                toastMessage: "Please select at least one entry to deploy",
                actionText: "",
                variant: "error",   // success, error, warning, info
                position: "top",   // top, bottom           
                align: "center",   // left, center, right
                dismissible: true, // true, false
                light: false,      // true, false
                timeout: 8000,     // time in milliseconds
                inline: false,     // true, false
                autoDismiss: true, // true, false
                zIndex: 11
            })
        }
        setSee(true);
        const payload = {
            data: selectedEntries
        }
        dispatch(fetchExceptionEntries(payload)).then((d) => {
            setApiResEntries(d?.payload?.data);
            parentChildTableModal(d?.payload?.data);
        });
    }

    function parentChildTableModal(data) {
        let dataArr = [];
        dataArr = groupData(data);
        setGroupedData(dataArr);
    }

    function groupData(data) {
        const groupedData = {};
        data?.forEach((item) => {
            const key = `${item.supplier_name}-${item.platform}`;
            if (!groupedData[key]) {
                groupedData[key] = { supplier_name: item.supplier_name, platform: item.platform, children: [] };
            }
            // Filter out children with all null values
            const hasNonNullValue = Object.keys(item).some(key => key !== 'supplier_name' && key !== 'platform' && item[key] !== null);
            if (hasNonNullValue) {
                groupedData[key].children.push(item);
            }
        });
        return Object.values(groupedData);
    };

    useEffect(() => {
        const selectedEntries = selectedRows.map(rowId => {
            const entry = apiRes?.find(row => row.omms_exception_reporting_id === rowId);
            return { supplier: entry?.supplierName, platform: entry?.platform };
        }).filter(entry => entry.supplier !== undefined || entry.platform !== undefined); // Remove entries with both undefined
        setSelectedEntries(selectedEntries);
    }, [selectedRows, apiRes]);

    function handleDeployHere(group, str) {
        if (str === 'deploy here') {
            deployHere.edit_values.is_deployed_here = true;
            deployHere.edit_values.is_deployed = false;
        }
        if (str === 'deployed as new') {
            deployHere.edit_values.is_deployed_here = false;
            deployHere.edit_values.is_deployed = true;
        }
        const filteredData = selectedEntries.filter(entry => entry.supplier === group.supplier_name && entry.platform === group.platform);
        const filteredDataIds = filteredData.map(entry => apiRes?.find(row => row.supplierName === entry.supplier && row.platform === entry.platform).omms_exception_reporting_id);
        deployHere.edit_values.matchedEntries = group.children;
        deployHere.edit_values.exception_id = filteredDataIds;

        dispatch(editException(deployHere))
            .then(() => {
                const remainingEntriesID = selectedRows.filter(id => !filteredDataIds.includes(id));
                setRemainingIDs(remainingEntriesID);
                const fetchData = remainingEntriesID.map(id => {
                    const entry = apiRes?.find(row => row.omms_exception_reporting_id === id);
                    return entry ? { supplier: entry?.supplierName, platform: entry?.platform } : undefined;
                }).filter(item => item !== undefined); // Filter out undefined values
                setSelectedEntries(fetchData);
                if (fetchData.length < 1 || fetchData[0] === undefined) {
                    dispatch(fetchException(apiPayload))
                    .then((d) => {
                        setApiRes(d?.payload?.data);
                    });
                    addToast({
                        toastMessage: "Your changes have been saved successfully ",
                        actionText: "",
                        variant: "success",
                        position: "top",
                        align: "center",
                        dismissible: true,
                        light: false,
                        timeout: 8000,
                        inline: false,
                        autoDismiss: true,
                        zIndex: 11
                    })
                    setSee(false);
                    setSelectedRows([]);
                    return;
                }

                const payload = {
                    data: fetchData
                }

                dispatch(fetchExceptionEntries(payload)).then((d) => {
                    console.log(d.payload.data, 'd');
                    setApiResEntries(d?.payload?.data);
                    parentChildTableModal(d?.payload?.data);
                }).then(() => {
                     dispatch(fetchException(apiPayload))
                    .then((d) => {
                        setApiRes(d?.payload?.data);
                    });
                addToast({
                    toastMessage: "Your changes have been saved successfully ",
                    actionText: "",
                    variant: "success",
                    position: "top",
                    align: "center",
                    dismissible: true,
                    light: false,
                    timeout: 8000,
                    inline: false,
                    autoDismiss: true,
                    zIndex: 11
                })
                });
                if(remainingEntriesID.length < 1){
                    setSee(false);
                    setSelectedRows([]);
                    setSelectedEntries([]);
                }
            });

    }

    const handleRowSelect = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(prevSelectedRows => prevSelectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows(prevSelectedRows => [...prevSelectedRows, rowId]);
        }
    }
    useEffect(() => {
        // Check if all rows are selected after the state update
        if (apiRes?.length > 0) {
            setSelectAll(apiRes?.every(row => selectedRows.includes(row.omms_exception_reporting_id)));
        } else {
            setSelectAll(false);
        }
    }, [selectedRows, apiRes]);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(apiRes?.map(row => row.omms_exception_reporting_id));
        }
        // Toggle the selectAll state
        setSelectAll(!selectAll);
    }

    function archive() {
        window.location.href = '/omms/ExceptionArchive';
    }

    const handleHeaderClick = (columnName) => {
        setInputColumns(prevState => ({ ...prevState, [columnName]: true }));
    }

    const keyMapping = {
        'SupplierName': 'SupplierName',
        'MetricLabel': 'MetricLabel',
        'Platform': 'Platform',
        'activityCount': 'activityCount',
    };


    const handleInputChange = (columnName, value) => {
        value = value.replace(/,\s+/g, ',');
        const mappedKey = keyMapping[columnName] || columnName;
        setSearchValue(prevState => ({
            ...prevState,
            [mappedKey]: value
        }));
        if (value.trim() !== '') {
            setInputColumns(prevState => ({ ...prevState, [columnName]: true }));
        } else {
            // If input is empty, allow the column to close
            const newInputColumns = { ...inputColumns };
            delete newInputColumns[columnName];
            setInputColumns(newInputColumns);
        }
    }


    const handleSearch = () => {
        setApiRes()
        dispatch(fetchException(apiPayload)).then((d) => (setApiRes(d?.payload?.data)));
    }

    const handleClear = () => {
        setIsON1(false)
        setIsON2(false)
        setIsON3(false)
        setIsON4(false)
        setSupplierName('')
        setPlatform('')
        setMetricLabel('')
        setActivityCount('')
        setSearchValue({
            supplierName: '',
            Platform: '',
            MetricLabel: '',
            activityCount: ''
        })
        // Reset the inputColumns state
        setInputColumns({});
    
        const clearPayload = {
            active_flag: true,
            supplier_name: '',
            metric_label: '',
            platform: '',
            count: '',
            pageSize: 10,
            pageNumber: currentPage,
            status: '',
            contact_type: '',
        }
    
        dispatch(fetchException(clearPayload)).then((d) => {
            setApiRes(d?.payload?.data);
            setTotalPage(d?.payload?.numberOfPages);
        })
    }

    const firstPage = () => {
        setCurrentPage(1)
    }
    const lastPage = () => {
        setCurrentPage(totalPages)
    }


    return (
        <>
            <HeaderNav msg={"Exception Reporting"} />
            <div className="ms-3 mt-2 p-2">
                <LdsLink href="/omms">
                    <LdsIcon
                        description="CaretLeft"
                        label="search"
                        name="CaretLeft"
                        inline
                    />
                    Back to Overview
                </LdsLink>
            </div>
            <LdsTile className='overview-hero ' >
            <div className="d-flex ">
                    <div>
                    <span className='overview-text'>Exception <br/> Overview</span>
                    </div>
                    <div className='button-container-ov'>
                        <LdsButton onClick={() => deployButton('deploy')} className='col buttons'>Deploy</LdsButton>
                        <LdsButton onClick={() => handleEdit('rnn')} className='col buttons'>Review Not Needed</LdsButton>
                        <LdsButton onClick={archive} className='col buttons'>View Archives</LdsButton>
                        <LdsButton onClick={() => handleClear()} className='col buttons'>Clear Search</LdsButton>
                    </div>
                </div>
            </LdsTile>

            {loader && <div className='container mt-4'><LdsLoadingSpinner size={50} animationSpeed={500} />

            </div>
            }

            {
                (apiRes?.length < 1 && !loader) &&
                <div className="styless">
                    <span>No Data Available</span>
                </div>
                
            }

<LdsModal
                modalId="Sub"
                open={see}
                setModalOpen={() => setSee()}
                heading="Recommended Deployment"
                style={{ zIndex: 100 }}
            >
                {loader && <div className='container mt-4'><LdsLoadingSpinner size={50} animationSpeed={500} />

                </div>
                }
                {!loader && <div>
                    {groupedData.map((group, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                            {/* Parent Table */}

                            <LdsTable className=" mt-4 ">
                                <thead>
                                    <tr>
                                        <th style={{fontSize:'20px', fontWeight:'bold'}}>Supplier Name</th>
                                        <th style={{fontSize:'20px', fontWeight:'bold'}}>Platform</th>
                                        <th  style={{ fontSize:'20px', fontWeight:'bold'}}>Actions </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{group.supplier_name}</td>
                                        <td>{group.platform}</td>
                                        <td ><LdsButton onClick={() => handleDeployHere(group, 'deploy here')} >Deploy here</LdsButton> <LdsButton onClick={() => handleDeployHere(group, 'deployed as new')}>Deploy as New</LdsButton></td>

                                    </tr>
                                </tbody>
                            </LdsTable>
                            {group.children?.length > 0 &&  <LdsTable className="sub-table mt-4 mb-3 ">
                                <thead>
                                    <tr>
                                        <th>Delivery Source</th>
                                        <th>Delivery Channel</th>
                                        <th>Delivery Source Type</th>
                                        <th>Vehicle</th>
                                        <th>Engagement Based Tactic</th>
                                        <th>Is Digital</th>
                                        <th>Is PLD</th>
                                        <th>Is RM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {group.children.map((child, childIndex) => (
                                        <tr key={childIndex}>
                                            <td>{child.delivery_source || "N/A"}</td>
                                            <td>{child.delivery_channel || "N/A"}</td>
                                            <td>{child.delivery_source_type || "N/A"}</td>
                                            <td>{child.vehicle || "N/A"}</td>
                                            <td>{child.engagement_based_tactic || "N/A"}</td>
                                            <td>{child.is_digital ? 'True' : 'False'}</td>
                                            <td>{child.is_pld ? 'True' : 'False'}</td>
                                            <td>{child.is_rm ? 'True' : 'False'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </LdsTable>
}
{group.children.length === 0 && <div style={{textAlign: 'center',fontSize:'20px', marginTop:'35px'}}>No match found!!!</div>}

                        </div>
                    ))}


                </div>}


            </LdsModal>

            {
                (apiRes?.length >= 1 && !loader) &&
                <div>
                    <LdsTable className="container">
                        <thead >
                            <tr>
                                <th scope="col">
                                    <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                                </th>
                                <th scope="col" className="table-ends">
                                    <div class="attriDivCore">
                                        Supplier Name
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => { setIsON1(!statusSearchFld1) }}
                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld1 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld-exception'
                                                id='orgName'
                                                value={supplierName}
                                                onChange={(e) => setSupplierName(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>
                                <th scope="col" className='table-ends'>
                                    <div class="attriDivCore">
                                        Platform
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => {
                                                    setIsON2(!statusSearchFld2);
                                                }}

                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld2 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld'
                                                id='orgName'
                                                value={platform}
                                                onChange={(e) => setPlatform(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>
                                <th scope="col" className='table-ends'>
                                    <div class="attriDivCore">
                                        Metric Label
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => { setIsON3(!statusSearchFld3) }}
                                            />
                                        </div>
                                    </div>
                                    {statusSearchFld3 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld'
                                                id='orgName'
                                                value={metricLabel}
                                                onChange={(e) => setMetricLabel(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>
                                <th scope="col" className='table-ends'>
                                    <div class="attriDivCore">
                                        Activity Count
                                        <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                                            <LdsIcon
                                                description="MagnifyingGlass"
                                                label="search"
                                                name="MagnifyingGlass"
                                                class="icon-size"
                                                style={{
                                                    height: '15px',
                                                    marginBottom: '-2rem',
                                                    maxWidth: '2.5rem',
                                                    minWidth: '2.5rem'
                                                }}
                                                onClick={() => { setIsON4(!statusSearchFld4) }}
                                            />
                                            </div>
                                    </div>
                                    {statusSearchFld4 &&
                                        <div style={{ width: '12rem' }}>
                                            <LdsTextField
                                                // className='text-fld'
                                                id='orgName'
                                                value={activityCount}
                                                onChange={(e) => setActivityCount(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                                                onKeyDown={(e) => { if (e.key === "Enter") { handleSearch() } }}
                                                name='orgName' />
                                        </div>
                                    }
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {apiRes?.map((resp) =>
                                <tr role="row">
                                    <td role="rowheader" scope="row">
                                        <input type="checkbox" checked={selectedRows.includes(resp?.omms_exception_reporting_id)} onChange={() => handleRowSelect(resp.omms_exception_reporting_id, resp)} />
                                    </td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.supplierName}</td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.platform}</td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.metricLabel}</td>
                                    <td className='ta-start' role="rowheader" scope="row">{resp?.activityCount}</td>
                                </tr>
                            )}
                        </tbody>
                    </LdsTable>
                    {!loader &&
                        <div className="d-flex">
                            <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => firstPage()}>First</LdsButton>

                            <LdsPagination
                                currentPage={currentPage}
                                onNextClick={() => setCurrentPage(currentPage + 1)}
                                onPrevClick={() => setCurrentPage(currentPage - 1)}
                                setCurrentPage={setCurrentPage}
                                useButtons={true}
                                pageRangeVisible={5}
                                totalPages={totalPages}
                            />
                            <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => lastPage()}>Last</LdsButton>
                        </div>
                    }
                </div>
            }


        </>
    );
}

export default React.memo(ExceptionReporting);